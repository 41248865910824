
import * as __star__ from '@clayui/modal';

const {
default: __default__,
Body,
ClayModalProvider,
Context,
Header,
Item,
ItemGroup,
Modal,
Subtitle,
SubtitleSection,
Title,
TitleIndicator,
TitleSection,
useModal,
__esModule
} = __star__;

export {
Body,
ClayModalProvider,
Context,
Header,
Item,
ItemGroup,
Modal,
Subtitle,
SubtitleSection,
Title,
TitleIndicator,
TitleSection,
useModal,
__esModule
};
export default __default__;
